<template>
  <footer class="text-center lg:text-left bg-gray-10 text-gray-600">
    <div class="mx-6 py-4 text-center md:text-left">
      <div class="grid grid-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
        <div class="">
          <h6 class="
            uppercase
            font-semibold
            mb-4
            flex
            items-center
            justify-center
            md:justify-start
          ">
            About
          </h6>
          <p>
            Not owned or operated by any government agencies
          </p>
        </div>
        <div class="">
        </div>
        <div class="">
        </div>
        <div class="">
          <h6 class="uppercase font-semibold mb-4 flex justify-center md:justify-start">
            Contact
          </h6>
          <p class="flex items-center justify-center md:justify-start mb-4">
            New York, NY 10012, US
          </p>
          <p class="flex items-center justify-center md:justify-start mb-4">
            info@example.com
          </p>
        </div>
      </div>
    </div>

  </footer>
</template>