<template>
  <div id="app">
    <div class="flex flex-col h-screen justify-between max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <IntroBlock/>
        <footer-block/>
    </div>
  </div>
</template>

<script>
import IntroBlock from './components/IntroBlock.vue'
import FooterBlock from './components/FooterBlock.vue'
import ContentBlock from "@/components/ContentBlock";

export default {
  data() {
    return {
      message: null,
      text: null
    }
  },
  methods: {
    greet() {
      this.$toast.add({severity: 'info', summary: 'Hello ' + this.text});
      this.message = 'Hello ' + this.text;
    }
  },
  components: {
    IntroBlock,
    FooterBlock
  }
}
</script>
