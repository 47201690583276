/* eslint-disable */
import { createApp } from 'vue'
import App from './App.vue';

import PrimeVue from 'primevue/config';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';

import './index.css';
import './assets/tailwind.css'
// import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/themes/tailwind-light/theme.css';

import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
// import 'primeflex/primeflex.css';


const app = createApp(App);

app.use(PrimeVue);
app.use(ToastService);

app.component('InputText', InputText);
app.component('Button', Button);
app.component('Toast', Toast);

app.mount('#app')

console.log('prenv:', process.env);
console.log('env:', env);
