<template>
  <div class="py-12 bg-white">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="lg:text-center">
        <div class="flex flex-wrap justify-center">
          <img
              alt="Covid Green Сertificate Validate for European Countries" src="../assets/logo.png"
              width="120"
              class="p-1 bg-white  max-w-sm"
          />
        </div>

        <h2 class="text-base text-indigo-600 font-semibold tracking-wide uppercase">Digital Green Certificate</h2>
        <p class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Check your corona passport
        </p>
        <p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
          The EU Digital COVID Certificate Regulation entered into application on 01 July 2021. EU citizens and
          residents will now be able to have their Digital COVID Certificates issued and verified across the EU.
        </p>
      </div>

      <div class="mt-20 border md:border-dotted  rounded-md p-8 flex items-center justify-center">
        <FileUpload mode="basic" name="demo[]" url="./upload" :auto="true" />
      </div>

      <div class="mt-20">
        <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
          <div v-for="feature in features" :key="feature.name" class="relative">
            <dt>
              <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                <component :is="feature.icon" class="h-6 w-6" aria-hidden="true"/>
              </div>
              <p class="ml-16 text-lg leading-6 font-medium text-gray-900">{{ feature.name }}</p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-500">
              {{ feature.description }}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import {AnnotationIcon, GlobeAltIcon, LightningBoltIcon, ScaleIcon} from '@heroicons/vue/outline'
import FileUpload from 'primevue/fileupload';
import FooterBlock from "@/components/FooterBlock";

const features = [
  {
    name: 'Vaccinated against COVID-19',
    description:
        'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
    icon: GlobeAltIcon,
  },
  {
    name: 'Received a negative test result or',
    description:
        'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
    icon: ScaleIcon,
  },
  {
    name: 'Recovered from COVID-19',
    description:
        'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
    icon: LightningBoltIcon,
  },

]

export default {
  setup() {
    return {
      features,
    }
  },
  components: {
    FooterBlock,
    FileUpload
  }
}
</script>